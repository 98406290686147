const Config = {
  API_URL: 'https://dev.api.mipana.com/admin',
  VERSION_NUMBER: '1.189.0',
  GW_API_REQ: '8935b2f884fbfe785620c0a19bd660d3c60e577cbdadefdb1c2106d961a275c3',
  API_KEY_PAYMENT_LINK: 'z3hpfDMOXJ8bkLPASUB6H9fHi3vqnCy79KMdApqB',
  API_URL_PAYMENT_LINK: 'https://link-de-pagos.api.dev.mipana.com',
  AMPLIFY: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_AS9OG3TuF',
      userPoolWebClientId: '9vbih7ofc7s37u8man3lkuuvb',
      oauth: {
        domain: 'cg-tp-back-office-dev.auth.us-east-1.amazoncognito.com',
        scope: ['openid'],
        redirectSignIn: 'https://dev.backoffice.mipana.com/dashboard',
        redirectSignOut: 'https://dev.backoffice.mipana.com/login',
        responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
  },
  MAPS_API_KEY: 'AIzaSyAFmAdCVGycA8ZSGcnrUePcy9XoMl9qqNU',
};
export default Config;
